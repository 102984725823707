@import url("https://fonts.googleapis.com/css2?family=Comfortaa&family=Inter&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  background-image: url("/public/images/BG-Image.jpg");
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.container {
  width: 100%; /* Adjust as needed */
  border: 1px solid #ccc; /* Optional, for visual reference */
  padding: 10px; /* Optional, for visual reference */
}

.break-char {
  display: block;
  word-wrap: break-word; /* Allows the text to wrap within its container */
  overflow-wrap: break-word; /* Ensures long words are wrapped */
  word-break: break-all; /* Breaks long words at arbitrary points */
  white-space: normal; /* Ensures normal word wrapping */
  border: 1px solid #000; /* Optional, for visual reference */
  padding: 10px; /* Optional, for visual reference */
}
